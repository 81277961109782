@import url('https://fonts.cdnfonts.com/css/fluffy-cake-95309');

.proLinks {
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;

  a {
    background-color: #86d7ec;
    padding: 0px;
    color: #3c45b4f0 !important;
  }
}

.card {
  border-radius: 20px;
  border: 4px solid black;
  background-color: #86d7ec;
  margin-bottom: 40px;
  box-shadow: 6px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 10px 10px 10px 10px;
}

#cardImg {
  width: 100%;
  max-width: 300px;
  max-height: 160px;
  border-radius: 15px;
  margin-bottom: 15px;
}

#encore {
  width: 100%;
  max-width: 315px;
  max-height: 200px !important;
  border-radius: 15px;
  margin-bottom: 15px;
}


.card-body {
  background-color: #86d7ec;
  border-radius: 30px;
  width: 386px !important;
  height: 5%;
}

.card-text {

  font-family: 'Fluffy Cake', sans-serif;
  width: 355px !important;
  background-color: #86d7ec;
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-align:justify;
}

.backImg {
  background-color: #86d7ec;
}

.card-title {
  background-color: #86d7ec;
  font-family: 'Shadows Into Light Two', cursive !important;
  font-size: 30px;
  font-weight: 600;
  color: #3c45b4f0;
  margin-bottom: 15px;
}



#projectTitle {
  margin-bottom: 10px;
  margin-top: 25px;
  color: #3c45b4f0;
  font-size: 50px;
  font-weight: 600;
}


#projects {
  margin-top: 15px;
}



@media (min-width: 900px) {
  .card-body {
    width: 400px;
  }

  .card-text {
    width: 380px;
    // padding-right: 10px;
  }


  .card-image {
    width: 100%;
    max-width: 300px;
  }
  #back-to-top {
    margin-bottom: 30px;
  }
}

#wave {
  height: 60px;
  margin-bottom: 20px;
}



#proTitle {
  margin-bottom: 60px;
  margin-top: 25px;
  color: #3c45b4f0;
  font-size: 50px;
  font-weight: 600;
}


.card-subtitle {
  background-color: #86d7ec;
  font-size: 20px;
  font-family: 'Shadows Into Light Two', cursive !important;
  padding-bottom: 20px;
  color: lightcoral !important;
  font-weight: 600;
}


@media (max-width: 767px) {

  .card-title {
    color: lightcoral;
  }

  .card-subtitle {
    color:#3c45b4f0 !important;
  }

  #projects {
    width: 320px;
    margin-right: 20px;
  }

  #proTitle {
    margin-top: 20px !important;
  }

  #proTitle {
    margin-bottom: 10px !important;
  }

  .card {
    border: 4px solid black;
    width: 320px;
    margin-bottom: 35px;
    box-shadow: 6px 10px 0 rgba(0, 0, 0, .2);
    padding: 0px 0px 0px 0px;
  }

  .card-body {
    width: 300px !important;

  }

  .card-text {
    width: 280px !important;
    // padding-right: 10px;
  }

  .projects-scroll-container {
    
    overflow-y: unset !important;
    height: 650px !important;
    width: 340px !important;
    background-color: #b9d6f0 !important;
    border: none !important;
    
  }

  .scroll-content {
    background-color: #b9d6f0 !important;
  }

  .col {
    background-color: #b9d6f0!important;
  }

  .projects-scroll-container .card:first-child {
    margin-left: 0px !important; 
  }

  .projects-row-wrapper {
    background-color: #b9d6f0 !important;
  }

  .wrap {
    background-color: #b9d6f0 !important;
  }

  .proLinks a {
    color: lightcoral !important;
  }
}

.projects-scroll-container {
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 40px;
  height: 640px;
  width: 945px;
  background-color: #f6eb85;
  border-radius: 10px;
  border: 4px solid black;
}

.col {
  background-color: #f6eb85;
  margin-top: 10px;
}

.projects-scroll-container .card:last-child {
  margin-right: 10px; 
}
.projects-scroll-container .card:first-child {
  margin-left: 10px; 
}
.projects-row-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #f6eb85;
}

.scroll-content {
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
  background-color: #f6eb85;
}


.scroll-content>* {
  flex: 0 0 auto;
}

.scroll-content .card {
  margin: 0;
}

.col {
  height: 100%;
}

.projects-scroll-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  
  
}


.projects-scroll-container::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 10px;
}

.projects-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #828282;
}

#sub {
  font-size: 20px;
  color: lightcoral;
  font-family: 'Shadows Into Light Two', cursive !important;
  font-weight: 600;
  
  margin: 0 auto;
  margin-bottom: 20px;
}

#video {
  text-decoration: none;
  font-family: "Shadows Into Light", cursive !important;
  color: lightcoral !important;
  font-weight: 600;
  font-size: 16px;
  background-color: #86d7ec;
}

