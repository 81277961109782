#mongoose-image {
  width: 35px;
  filter: hue-rotate(180deg) saturate(0) brightness(0.5); 
   background-color: transparent;
  padding-bottom: 15px;
}

#wave2 {
  height: 60px;
  margin-bottom: 20px;
  margin-top: 40px;
}

#skillsP {
  font-family: 'Shadows Into Light Two', cursive !important;
  font-size: 22px;
  color: black;
  text-align: left;
  background: none;
  z-index: 0;
}

#skillSpan {
  font-family: 'Shadows Into Light Two', cursive !important;
  color: #3c45b4f0;
  font-size: 28px;
  background: none;
  font-weight: 600;
  z-index: 0;
}

#ice {
  height: 180px;
  width: 130px;
  border-radius: 15px;
  margin-top: 15px;
  margin-bottom: 50px;
}

.skillPics {
  display: flex;
  justify-content: space-around;
}

#skills {
  width: 900px;
}

#sub2 {
  font-family: 'Fluffy Cake', sans-serif;
  font-size: 28px;
  color: lightcoral;
  font-weight: 600;
  text-align: left;
  margin: 20px 0px 15px 12px;
}


@media (max-width: 767px) {
  #skills {
    width: 320px;
  }

  #ice {
    height: 95px;
    width: 70px;
  }

  h3 {
    text-align: center !important;
  }
  #sub2 {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 25px;
    text-align: center;
  }

  .skillPics {
    height: 100px;
    margin-bottom: 35px;
    justify-content: space-between;
  }

  #skillsP {
    text-align: center;
  }
  .skills-content-container .skills-row .h3-col {
    background-color: #b9d6f0 !important;
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}


.skills-content-container {

  margin-bottom: 2%;
  margin-top: 2%;
  


  .skills-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    .h3-col {
      margin-bottom: 10px;
      background-color: #b9d6f0;

      h3 {
          font-size: 30px !important;
          margin-bottom: 20px;
          
          text-align: left;

          font-family: 'Shadows Into Light Two', cursive !important;
          color: rgba(60, 69, 180, 0.9411764706);
          font-size: 16px;
          font-weight: 600;
        }
      }

    .skills-row-wrapper {
      text-align: center;
      flex-wrap: nowrap;
      overflow-x: scroll;
      margin-bottom: 20px;
      margin: 0 auto;

      div {

        p {
          font-size: 12px;
        }
      }
    }


    
    .skills-scroll-container {
      overflow-x: auto;
      margin-bottom: 20px;
      height: 625px;
    }
    
    .skills-row-wrapper {
      display: flex;
      flex-wrap: nowrap;
      gap: 30px;
      overflow-x: auto;
      scrollbar-width: thin; 
      scrollbar-color: #8b8888 #b9d6f0; 
      margin: 0 auto;
    }
    
  
    .skills-row-wrapper > * {
      flex: 0 0 auto;
    }
    

    .skills-row-wrapper::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #b9d6f0;
    }
    
    .skills-row-wrapper::-webkit-scrollbar-thumb {
      background-color: #b9d6f0;
      border-radius: 10px;
    }
    
    .skills-row-wrapper::-webkit-scrollbar-thumb:hover {
      background-color: #828282;
    }
    

    .skills-row-wrapper::-webkit-scrollbar-thumb {
      scrollbar-arrow-color: #b9d6f0;
    }}
  }

  .skills-content-container .skills-row .skills-row-wrapper > * {
    margin: 0 auto;
  }