
h1 {
  font-family: 'Shadows Into Light Two', cursive !important;
  color: #3c45b4f0;
  position: relative;
  z-index: 9999;
}

#junior {
  color: lightcoral;
  font-weight: 600;
  margin-bottom: 15px;
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  @extend %flex-center;
  background-color: #b9d6f0;
  padding: 0px;

  .hero {
    text-align: center;
    background-color: #b9d6f0;
    border-radius: 8px;
  }
}

#sun {
  height: 80px;
  width: 80px;
  position: relative;
  z-index: 9999;
  top: 15px;
}


h2 {
  font-size: 28px;
  font-family: 'Shadows Into Light Two', cursive;
  color: #00c5ff;
  margin-bottom: 20px;
}

#homep {
  margin-top: 10px;
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 20px;
  color: lightcoral;
  font-weight: 600;
}

#beach {
  transition: opacity 1s ease-in-out;
  border-radius: 15px;
  overflow: hidden;
  border: 4px solid black;
}

#beach:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}

#beach.active {
  opacity: 1;
  z-index: 0;
}


@media (max-width: 767px) {
  #beach {
    max-width: 92%;
    max-width: 400px;
    max-height: 200px !important;
  }

  #sun {
    top: 5px;
  }
  #junior {
    margin-bottom: 5px;
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}

.flex-row {
  flex-direction: row !important;
}


#tom {
  padding: 0px;
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 0px;
}


#spanHome {
  color: #3c45b4f0;
  font-weight: 600;
}

.giphy-img {
  animation-name: drop-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background: transparent;
}

@keyframes drop-in {
  from {
    transform: translateY(-80%);
  }

  to {
    transform: translateY(0);
  }
}


.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 340px;
  width: 600px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .image-container {
    padding: 0px;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 330px;
    height: 220px;

  }

  .image-container img {
    width: 330px;
    height: 280px;
  }
}

@media (max-width: 767px) {
  .home {
    max-height: 100vh;

  }

  .home .hero {
    margin-top: 30px;
  }
}