
@import url('https://fonts.cdnfonts.com/css/fluffy-cake-95309');

#ball {
  height: 75px;
  width: 75px;
  margin-left: 15px;
  
}

#music {
  margin-top: 10px;
  height: 55px;
  width: 55px;
  margin-bottom: 10px;
}

.links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

a {
  text-decoration: none;
  font-family: 'Shadows Into Light', cursive !important;
  color: lightcoral!important;
  font-weight: 600;
  font-size: 20px;
}

.about {
  @extend %flex-center;
  flex-direction: column;
  background-color: #b9d6f0;

  .about-hero {
    text-align: center;
    padding: 20px;
    background-color: #b9d6f0;
    border-radius: 8px;
  }
}

#hobbies {
  font-weight: 600;
  font-size: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #3c45b4f0;
}

#hobbies2 {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #3c45b4f0;
}


.about {
  text-align: center;
  padding: 20px;
  background-color: #b9d6f0;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .about {
    text-align: center;
    padding: 20px;
    background-color: #b9d6f0;
    border-radius: 8px;
    // margin-top: 50px;
  }
}

#aboutp {
  font-size: 22px;
  font-family: 'Fluffy Cake', sans-serif;
  font-weight: 600;
  max-width: 900px;
  text-align: justify;
  z-index: 1 !important;
  margin-bottom: 10px;
}

#aboutp2 {
  font-size: 18px;
  
  font-weight: 600;
  max-width: 900px;
  text-align: justify;
  z-index: 1 !important;
  
  margin-bottom: 10px;
}



#me {
  height: 160px;
  width: 145px;
  border-radius: 15px;
  margin-top: 25px;
  margin-bottom: 30px;
  opacity: 1;
  animation: spin 2s linear forwards;
}

#me.active {
  opacity: 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#aboutp strong {
  font-weight: bold;
  color: rgba(60, 69, 180, 0.9411764706);
}