
.navbar {
  height: 60px;
}

.flex-row .nav-link {
  padding: 0 1rem;
}

.portfolio-nav {
  z-index: 9999;
}


.nav {
  max-width: 1320px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 100vh !important;
  font-family: 'Shadows Into Light Two', cursive !important;
  font-size: 24px;
  color: #00c5ff !important;

  .nav-item {
    width: 160px;
    text-align: right;
    font-family: 'Shadows Into Light Two', cursive !important;
    color: #00c5ff;

    .nav-link {
      color: #898989;
      font-weight: 600;
      font-family: 'Shadows Into Light Two', cursive;

      &:hover,
      &.active {
        background-color: #9d008d99 !important;
        color: #000;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 767px) {
  .nav {
    font-size: 18px;
    color: #00c5ff !important;
    padding: 0px !important;
    flex-direction: row !important;
    max-width: 300px !important;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 0px;
    border: none;
    justify-content: space-evenly ;
  }

  .navbar-collapse {
    position: absolute;
    left: 65px; 
    width: auto; 
    z-index: 1000;
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: flex-end; 
  }
  
  .navbar-collapse .nav {
    flex-direction: row; 
  }
  


  .nav-item {
    font-weight: 600;
  }

  .navbar .nav-link {
    font-weight: 500 !important;
    
  }
  
}


.navbar .nav-link {
  font-family: 'Shadows Into Light Two', cursive !important;
  color: lightcoral !important;
  font-weight: 700;
  
}

.nav .nav-item {
  width: auto;
  text-align: center;
}

.nav-link.active {
  background-color: lightcoral !important;
  color: #000 !important;
  font-weight: 900 !important;
  border-radius: 18px;
  border: 4px solid black;
}

@media (max-width: 767px) {
  .nav-link.active {
    background-color: transparent !important;
    color: #3c45b4f0!important;
    box-shadow: none !important;
    border: none !important;
  }

  .navbar .nav-link {
    font-weight: 900 !important;
  }
  

  
}



