@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two:wght@300;400;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/fluffy-cake-95309');
@import './components/navbar.scss';
@import './components/projects.scss';
@import './components/about.scss';
@import './components/home.scss';
@import './components/skills.scss';
body {
  background-color: #b9d6f0;
  
}

* {
  box-sizing: border-box;
  background-color: #b9d6f0;

}

.site-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #b9d6f0;
}


// main {
//   flex-grow: 1;
//   padding: 2em 0;
// }


#back-to-top {
  border: none;
  font-family: 'Fluffy Cake', sans-serif !important;
  color: #3c45b4f0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

footer {
  font-size: 10px;
  text-align: center;
  font-family: 'Shadows Into Light Two', cursive !important;

}



@media (max-width: 767px) {
footer {
    padding: 10px;
    font-size: 8px;
  }
}